<template>
  <div class="customer-info" ref="customerInfo">
    <div class="customer-info-col">
      <div class="photo">
        <img src="@/assets/img/custom-photo.png" alt="" />
      </div>
      <div class="name">
        <div class="text">Denis</div>
        <div class="grade">
          <img src="@/assets/img/grade.png" alt="" />
        </div>
      </div>
      <div class="custom-number">Service NO.907 135 089</div>
      <div class="grade-progress">
        <sweet-progress
          :text-inside="true"
          :stroke-width="32"
          :percentage="27"
          :show-text="false"
          color="#7fc823"
        />
        <div class="level level-left">LV4</div>
        <div class="level level-right">LV5</div>
      </div>
      <div class="cost">
        <div class="cost-item">
          <div class="label">Balance</div>
          <div class="num">$30</div>
        </div>
        <div class="cost-item">
          <div class="label">Telco+wallet</div>
          <div class="num">$13.5</div>
        </div>
      </div>
      <div class="points">
        <img src="@/assets/img/points.png" class="points-img" alt="" />
        <div class="points-text">
          <div class="label">Telco points</div>
          <div class="num">1566</div>
        </div>
      </div>
    </div>
    <div class="customer-info-col" :class="{ 'small' : !isWide}">
      <div class="title">Ultrainternet Fibra</div>
      <div class="title">+ Mobile Smart</div>
      <div class="date">Feb 2023 - Aug 2023</div>
      <div class="tips">
        <div class="tips-item">
          <div class="circle blue"></div>
          <div class="label">Used</div>
        </div>
        <div class="tips-item">
          <div class="circle gray"></div>
          <div class="label">Unused</div>
        </div>
        <div class="tips-item">
          <div class="circle red"></div>
          <div class="label">Excess</div>
        </div>
      </div>
      <div class="used-summary">
        <div class="used-summary-text">
          <div class="left">Data Volume</div>
          <div class="right">
            <div class="black">10GB</div>
            <div class="gray">/20GB</div>
          </div>
        </div>
        <sweet-progress
          :text-inside="true"
          :stroke-width="24"
          :percentage="50"
          :show-text="false"
          color="#68c3ef"
        />
      </div>
      <div class="used-summary">
        <div class="used-summary-text">
          <div class="left">Mobile Voice</div>
          <div class="right">
            <div class="black">400 Mins</div>
            <div class="gray">/500 Mins</div>
          </div>
        </div>
        <sweet-progress
          :text-inside="true"
          :stroke-width="24"
          :percentage="80"
          :show-text="false"
          color="#68c3ef"
        />
      </div>
      <div class="used-summary">
        <div class="used-summary-text">
          <div class="left">SMS</div>
          <div class="right">
            <div class="black">150 Pieces</div>
            <div class="gray">/500 Pieces</div>
          </div>
        </div>
        <sweet-progress
          :text-inside="true"
          :stroke-width="24"
          :percentage="30"
          :show-text="false"
          color="#68c3ef"
        />
      </div>
    </div>
    <div class="customer-info-col">
      <div class="title">Bill History</div>
      <div class="echarts" ref="main" id="echarts-main"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'custom-info',
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: 'Direct',
            type: 'bar',
            barWidth: 36,
            data: [],
            itemStyle: {
              borderRadius: 18,
              color: '#69c4f0'
            },
            label: {
              show: true,
              verticalAlign: 'middle',
              position: 'top',
              distance: 10
            }
          }
        ]
      },
      myChart: undefined,
      xAxisData: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
      seriesData: [55, 60, 66, 60, 69, 69],
      observer: undefined,
      mainObserver: undefined,
      isWide: true
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const chartDom = document.getElementById('echarts-main')
      this.observer = new ResizeObserver(this.chartResize)
      this.mainObserver = new ResizeObserver(this.mainResize)
      const main = this.$refs.main
      const customerInfo = this.$refs.customerInfo
      this.observer.observe(main, { box: 'border-box' })
      this.mainObserver.observe(customerInfo, { box: 'border-box' })
      this.myChart = echarts.init(chartDom)
      this.myChart.setOption(this.option)
      setTimeout(() => {
        this.option.xAxis[0].data = this.xAxisData
        this.option.series[0].data = this.seriesData
        this.myChart.setOption(this.option)
      }, 500)
    },
    chartResize() {
      if (this.myChart) {
        try {
          this.myChart.resize()
        } catch (error) {}
      }
    },
    mainResize(val) {
      if (val[0] && val[0].contentRect && val[0].contentRect.width) {
        if (val[0].contentRect.width < 1000) {
          this.option.series[0].barWidth = 18
          this.option.series[0].itemStyle.borderRadius = 9
          this.myChart.setOption(this.option)
          this.isWide = false
        }else {
          this.option.series[0].barWidth = 36
          this.option.series[0].itemStyle.borderRadius = 18
          this.myChart.setOption(this.option)
          this.isWide = true
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.customer-info {
  display: flex;
  height: 480px;
  border-radius: 24px;
  border: 2px solid #c7c7c7;
  overflow: hidden;
  background-color: #fff;

  &-col {
    flex: 1;
    padding: 16px;

    & + & {
      border-left: 2px solid #c7c7c7;
    }

    .photo {
      margin: 10px auto;
      text-align: center;

      img {
        width: 100px;
        height: 100px;
      }
    }

    .name {
      margin: 20px auto;
      display: flex;
      align-items: center;
      justify-content: center;

      .text {
        font-size: var(--20px-to-rem);
        font-weight: 500;
        color: #15225a;
      }

      .grade {
        position: relative;
        margin-left: 24px;
        top: 12px;
      }
    }

    .custom-number {
      margin: 20px auto;
      text-align: center;
      font-size: var(--16px-to-rem);
      color: #aeaeae;
    }

    .grade-progress {
      position: relative;
      margin: 20px;

      .level {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: var(--16px-to-rem);
        font-weight: 500;
        color: #fff;

        &.level-left {
          left: 12px;
        }

        &.level-right {
          right: 12px;
        }
      }

      :deep(.el-progress-bar__innerText) {
        display: none;
      }

      :deep(.el-progress-bar__outer) {
        background-color: #73818e;
      }
    }

    .cost {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;
      margin: 10px;
      border-bottom: 3px solid #c7c7c7;

      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        padding: 10px;

        .label {
          font-size: var(--14px-to-rem);
          color: #a1a1a1;
          font-weight: 400;
          margin-bottom: 8px;
        }

        .num {
          font-size: var(--25px-to-rem);
          color: #132158;
          font-weight: 500;
        }
      }
    }

    .cost-item + .cost-item {
      border-left: 2px solid #c7c7c7;
    }

    .points {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px;

      &-img {
        margin-right: 20px;
      }

      &-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .label {
          font-size: var(--14px-to-rem);
          color: #121212;
          font-weight: 400;
          margin-bottom: 8px;
        }

        .num {
          font-size: var(--25px-to-rem);
          color: #132158;
          font-weight: 500;
        }
      }
    }

    .title {
      margin-top: 10px;
      font-size: var(--20px-to-rem);
      font-weight: 500;
      color: #15225a;
      text-align: center;
    }

    .title + .title {
      margin-top: 8px;
    }

    .date {
      margin-top: 10px;
      font-size: var(--16px-to-rem);
      font-weight: 500;
      color: #15225a;
      text-align: center;
    }

    .tips {
      margin-top: 12px;
      padding: 20px;
      display: flex;
      justify-content: center;

      &-item {
        display: flex;
        align-items: center;
        font-weight: 500;

        .circle {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin-right: 16px;

          &.blue {
            background-color: #68c3ef;
          }

          &.gray {
            background-color: #d1d2d4;
          }

          &.red {
            background-color: #fa7662;
          }
        }
      }

      .tips-item + .tips-item {
        margin-left: 12px;
      }
    }

    .used-summary {
      margin-top: 12px;
      padding: 14px;

      &-text {
        display: flex;
        font-size: var(--14px-to-rem);
        font-weight: 500;
        margin-bottom: 8px;
        padding: 0 20px;

        .left,
        .right {
          display: flex;
        }

        .left {
          justify-content: left;
          color: #15225a;
        }

        .right {
          flex: 1;
          justify-content: right;

          .black {
            color: #000;
          }

          .gray {
            color: #d1d2d4;
          }
        }
      }

      :deep(.el-progress-bar__innerText) {
        display: none;
      }

      :deep(.el-progress-bar__outer) {
        background-color: #d1d2d4;
      }
    }

    #echarts-main {
      height: 400px;
      width: 100%;
    }

    &.small {
      .tips {
        font-size: 12px;
      }

      .circle {
        margin-right: 6px;
      }

      .used-summary-text {
        font-size: 12px !important;
      }
    }
  }
}
</style>
