import {defineStore} from 'pinia'

// 实时转写页面store
export const useVoiceTransferStore = defineStore('voiceTransfer', {
  state: () => {
    return {
      name: '',
      callId: '',
      agentId: ''
    };
  },
  getters: {},
  actions: {
    setTransfer(name, callId, agentId) {
      this.name = name;
      this.callId = callId;
      this.agentId = agentId;
    }
  }
})

// 记录callid的store
export const useCallIdStore = defineStore('callIdStore', {
  state: () => {
    return {
      callId: ''
    };
  },
  getters: {},
  actions: {
    setCallId(id) {
      this.callId = id;
    }
  }
})

// 记录callid的store
export const useNlpResultStore = defineStore('nlpResultStore', {
  state: () => {
    return {
      data: {}
    };
  },
  getters: {},
  actions: {
    setNlpData(data) {
      this.data = data;
    }
  }
})

export const useAiSummaryStore = defineStore('aiSummaryStore', {
  state: () => {
    return {
      sendEventName: '',
      data: {}
    };
  },
  getters: {},
  actions: {
    setAiSummaryData(data, sendEventName) {
      this.sendEventName = sendEventName
      this.data = data;
    }
  }
})

export const useAudioAiSwitchStore = defineStore('audioAiSwitchStore', {
  state: () => {
    return {
      sendEventName: '',
      data: false
    };
  },
  getters: {},
  actions: {
    setAudioAiSwitchData(data, sendEventName) {
      this.data = data;
      this.sendEventName = sendEventName
    }
  }
})

export const useAiAssistantStore = defineStore('aiAssistantStore', {
  state: () => {
    return {
      sendEventName: '',
      data: {}
    };
  },
  getters: {},
  actions: {
    setAiAssistantData(data, sendEventName) {
      this.sendEventName = sendEventName
      this.data = data;
    }
  }
})

export const sendAiStatusStore = defineStore('aiStatusStore', {
  state: () => {
    return {
      sendEventName: '',
      data: false
    };
  },
  getters: {},
  actions: {
    setAiStatus(data, sendEventName) {
      this.sendEventName = sendEventName
      this.data = data;
    }
  }
})

export const sendAiDataStore = defineStore('aiDataStore', {
  state: () => {
    return {
      sendEventName: '',
      data: {}
    };
  },
  getters: {},
  actions: {
    setAiData(data, sendEventName) {
      this.sendEventName = sendEventName
      this.data = data;
    }
  }
})

export const sendSummarySwitchStore = defineStore('summarySwitchStore', {
  state: () => {
    return {
      sendEventName: '',
      data: false
    };
  },
  getters: {},
  actions: {
    setSummarySwitch(data, sendEventName) {
      this.sendEventName = sendEventName
      this.data = data;
    }
  }
})