<template>
  <div
    class="custinfo-content-between"
    v-if="showCustomerMsgDataCfgResultModel && showCustomerMsgDataCfgResultModel.length > 0"
  >
    <!-- 客户信息标题 -->
    <div
      class="between-title"
      v-text="$t('agentconsole.contact.customerInfo')"
    >
    </div>

    <!-- 客户信息项 -->
    <div class="custinfo-content-item">
      <div
        class="msg-item"
        v-for="($item, $index) in showCustomerMsgDataCfgResultModel"
        :key=$item
        style="margin-bottom: 10px;"
      >
        <div
          class="item-label"
          v-text="$item.dataItemName"
          style="white-space: nowrap;display: block;max-width: 91px;text-overflow: ellipsis;overflow: hidden;"
          :title="$item.dataItemName"
        >
        </div>
        <div class="item-content tooLong200px number-plaintext" :title="$item.value||'--'" v-if="$item.dataType!=2">
          {{ $item.value || '--' }}
        </div>
        <div
          class="item-content tooLong200px number-plaintext"
          :title="$item.value ? ($item.values ? ($item.values[$item.value] || $item.value) : $item.value) : '--'"
          v-if="$item.dataType==2&&$item.currentStyle.fonts=='regular'"
        >
        <span
          style="padding: 3px;white-space: nowrap;text-overflow: ellipsis;display: block;width: fit-content;overflow: hidden;max-width: calc(100% - 6px);"
          :style="{'color':$item.currentStyle.fgColor!=''?$item.currentStyle.fgColor:'#1c1c1c','background-color': $item.currentStyle.bgColor!=''?$item.currentStyle.bgColor:'white'}"
        >
          {{ $item.value ? ($item.values ? ($item.values[$item.value] || $item.value) : $item.value) : '--' }}
        </span>
        </div>
        <div
          class="item-content tooLong200px number-plaintext"
          :title="$item.value ? ($item.values ? ($item.values[$item.value] || $item.value) : $item.value) : '--'"
          v-if="$item.dataType==2&&$item.currentStyle.fonts!='regular'"
        >
        <span
          style="padding: 3px;white-space: nowrap;text-overflow: ellipsis;display: block;width: fit-content;overflow: hidden;max-width: calc(100% - 6px);"
          :style="{'color':$item.currentStyle.fgColor!=''?$item.currentStyle.fgColor:'#1c1c1c','background-color': $item.currentStyle.bgColor!=''?$item.currentStyle.bgColor:'white'}"
        >
          <b v-if="$item.currentStyle.fonts=='bold'">
            {{ $item.value ? ($item.values ? ($item.values[$item.value] || $item.value) : $item.value) : '--' }}
          </b>
          <i v-if="$item.currentStyle.fonts=='italic'">
            {{ $item.value ? ($item.values ? ($item.values[$item.value] || $item.value) : $item.value) : '--' }}
          </i>
          <u v-if="$item.currentStyle.fonts=='underline'">
            {{ $item.value ? ($item.values ? ($item.values[$item.value] || $item.value) : $item.value) : '--' }}
          </u>
        </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {queryContactCustInfoConfig} from "@/views/agentconsole/api/audio-video-workbench";
import {useCallIdStore} from "@/views/agentconsole/stores";
import {queryCustomerCenterForUser, queryTenantSysParamByItemIds} from "@/views/ccmanagement/ccma-api";

let customerMsgDataCfgResultModel = ref([]);
let showCustomerMsgDataCfgResultModel = ref([]);
let currentCallId = ref('');
const callIdStore = useCallIdStore();
const appcubeCustomerCenterFeatureFlag = ref(false)

onMounted(() => {
  if (hasValidCallID()) {
    currentCallId.value = window.$Model.currentRecordCallId;
  }
  queryCustomerInfoByCallId(currentCallId.value);
});

const isCallIdUpdated = (callId) => {
  if (typeof (callId) == "undefined" || callId === '') {
    if (hasValidCallID()) {
      currentCallId.value = window.$Model.currentRecordCallId;
      return true;
    }
  }
  return false;
}

const hasValidCallID = () => {
  return !!(window.$Model.currentRecordCallId && window.$Model.currentRecordCallId != "" && window.$Model.callNumber && window.$Model.callNumber != "");
}

const initAppcubeCustomerFeatureFlag = async () => {
  const appcubeCustomerCenterFeatureCode = '990002303001'
  const enableCustomerCenterSysParamId = '6004005000003'
  await Promise.all([
    queryCustomerCenterForUser({'featureCode': appcubeCustomerCenterFeatureCode}),
    queryTenantSysParamByItemIds(enableCustomerCenterSysParamId)
  ]).then(([res1, res2]) => {
    if (res1 && res1.data === true && res2 && res2.data && res2.data.value == '1') {
      appcubeCustomerCenterFeatureFlag.value = true
    }
  })
}

const queryCustomerInfoByCallId = async (callId) => {
  await initAppcubeCustomerFeatureFlag() // 确保获取当前实时的appcubeCustomerCenterFeatureFlag值
  if (callId !== currentCallId.value) {
    return;
  }
  if (appcubeCustomerCenterFeatureFlag.value) {
    return;
  }
  const uri = (typeof (callId) == "undefined" || callId === '') ? '/service-cloud/rest/cc-management/v1/contactCustInfoConfig/queryAllConfigs' : '/service-cloud/rest/cc-management/v1/contactCustInfoConfig/queryCustomerInfoByCallId';
  queryContactCustInfoConfig(uri, callId).then(data => {
    if (data.returnCode == "2" && callId != "") {
      setTimeout(function () {
        queryCustomerInfoByCallId(callId);
      }, 2000);
      return;
    }
    // 处理页面初始化时currentRecordCallId全局变量未更新的场景
    if (isCallIdUpdated(callId)) {
      callId = currentCallId.value;
      queryCustomerInfoByCallId(callId);
      return;
    }
    if (data.returnCode == "0" && data.data && data.data.length > 0) {
      const tempData = data.data;
      initCurrentCustomerInfoData(tempData);
    } else {
      customerMsgDataCfgResultModel.value = [];
      showCustomerMsgDataCfgResultModel.value = [];
      window.$Model.customerMsgDataCfgResultModel = [];
    }
  })
}

const initCurrentCustomerInfoData = (tempDatas) => {
  const tempShowDatas = [];
  for (const tempConfig of tempDatas) {
    if (tempConfig.visible == 1) {
      tempShowDatas.push(tempConfig);
    }
    if (tempConfig.dataType != 2) {
      continue;
    }
    const tempStyle = tempConfig.showStyle && tempConfig.showStyle != "" ? JSON.parse(tempConfig.showStyle) : {
      fgColor: "",
      bgColor: "",
      fonts: "regular"
    };
    const kvs = tempConfig.valueRange.split(",");
    const values = {};
    for (const kv of kvs) {
      const keyValue = kv.split(":");
      values[keyValue[0] + ""] = keyValue[1];
      const tmpStyle = {fgColor: "", bgColor: "", fonts: "regular"};
      if (tempStyle[keyValue[0] + ""]) {
        tmpStyle.fgColor = tempStyle[keyValue[0] + ""].fgColor || tmpStyle.fgColor;
        tmpStyle.bgColor = tempStyle[keyValue[0] + ""].bgColor || tmpStyle.bgColor;
        tmpStyle.fonts = tempStyle[keyValue[0] + ""].fonts || tmpStyle.fonts;
      }
      tempStyle[keyValue[0] + ""] = tmpStyle;
    }
    tempConfig["styles"] = tempStyle;
    tempConfig["values"] = values;

    tempConfig.value = tempConfig.value ? (tempConfig.value + "") : null;
    if (tempConfig.value !== null && tempConfig.value !== "") {
      tempConfig.currentStyle = tempConfig.styles[tempConfig.value] ? tempConfig.styles[tempConfig.value] : {
        fgColor: "",
        bgColor: "",
        fonts: "regular"
      };
    } else {
      tempConfig.currentStyle = {fgColor: "", bgColor: "", fonts: "regular"};
    }
  }
  customerMsgDataCfgResultModel.value = tempDatas;
  showCustomerMsgDataCfgResultModel.value = tempShowDatas;
  window.$Model.customerMsgDataCfgResultModel = tempDatas;
}

const queryCustomerData = (state) => {
  if (state && state.callId !== '') {
    currentCallId.value = state.callId;
    queryCustomerInfoByCallId(state.callId);
  }
}
// 订阅callId数值的变化
callIdStore.$subscribe((mutation, state) => {
  queryCustomerData(state);
});

onMounted(() => {
  queryCustomerData(callIdStore);
});

</script>

<style lang="less" scoped>
@import '@/views/agentconsole/css/audioandvideoworkbench.css';
.custinfo-content-between {
  border-top: 1px solid rgba(243, 243, 243, 1);
  align-content: space-around;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 16px 0;
  box-sizing: border-box;
}

.custinfo-content-item {
  margin-top: 16px;
  width: 100%;
  max-height: 9.25rem;
  overflow-y: auto;
}

.between-title,
.msg-item {
  flex: 0 0 100%;
}

.msg-item {
  align-items: center;
  display: flex;
  height: 26px;
  line-height: 26px;
}

.item-label {
  font-size: var(--12px-to-rem);
  color: #707070;
  font-weight: 400;
  flex: 0 0 30%;
  cursor: pointer;
}

.item-content {
  font-size: var(--12px-to-rem);
  color: #1c1c1c;
  font-weight: 500;
  flex: 1;
}

.between-title {
  font-size: var(--14px-to-rem);
  color: #1c1c1c;
  font-weight: 600;
}

.tooLong200px {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px !important;
  overflow: hidden;
  display: inline-block;
}
i {
    font-style: italic;
}
</style>