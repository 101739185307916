<template>
  <div class="aicc-poc-scrolling-image">
    <div class="aicc-poc-scrolling-div">
      <div class="aicc-poc-scrolling-image-title">
        <div>NEXT BEST OFFERS</div>
        <div></div>
      </div>
      <div class="aicc-poc-customer-card">
        <div class="previous-record-btn" @click="changePreImg"></div>
        <template v-if="showDataFlag">
          <div class="one-card" v-for="(item, index) in showData" :key="index">
            <div class="card-image-div">
              <img class="card-imge" src="@/views/agentconsole/assets/img/FileIcon4@3x.svg" alt="" />
            </div>

            <div class="card-name">{{ item.name }}</div>
            <div class="card-price">${{ item.price }}</div>
          </div>
        </template>
        <template v-else>
          <div class="one-card2" v-for="(item, index) in showData2" :key="index">
            <div class="card-image-div">
              <img class="card-imge" src="@/views/agentconsole/assets/img/FileIcon4@3x.svg" alt="" />
            </div>

            <div class="card-name">{{ item.name }}</div>
            <div class="card-price">${{ item.price }}</div>
          </div>
        </template>

        <div class="next-record-btn" @click="changeNextImg"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PocCustomerInfo',
  data() {
    return {
      showFive: false,
      showOne: true,
      showDataFlag: true,
      showData: [
        {
          name: 'HUAWEI P60',
          price: '4888.00'
        },
        {
          name: 'iPhone 12',
          price: '5000.00'
        },
        {
          name: 'HUAWEI Mate 50',
          price: '4399'
        },
        {
          name: 'HUAWEI Mate Xs 2',
          price: '8999'
        }
      ],
      showData2: [
        {
          name: 'iPhone 12',
          price: '5000.00'
        },
        {
          name: 'HUAWEI Mate 50',
          price: '4399'
        },
        {
          name: 'HUAWEI Mate Xs 2',
          price: '8999'
        },
        {
          name: 'HUAWEI P50 Pro',
          price: '3888'
        }
      ],
      allData: [
        {
          name: 'HUAWEI P60',
          price: '4888.00'
        },
        {
          name: 'iPhone 12',
          price: '5000.00'
        },
        {
          name: 'HUAWEI Mate 50',
          price: '4399'
        },
        {
          name: 'HUAWEI Mate Xs 2',
          price: '8999'
        },
        {
          name: 'HUAWEI P50 Pro',
          price: '3888'
        }
      ]
    }
  },
  methods: {
    changeNextImg() {
      this.showDataFlag = false
      this.showFive = true
      this.showOne = false
      this.showData = this.allData.slice(1)
    },
    changePreImg() {
      this.showDataFlag = true
      this.showFive = false
      this.showOne = true
      this.showData = this.allData.slice(0, this.allData.length - 1)
    }
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
.aicc-poc-scrolling-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.aicc-poc-scrolling-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.aicc-poc-scrolling-image-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
  font-weight: 700;
}

.aicc-poc-customer-card {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 0px 16px;
}

.one-card {
  width: 20%;
  height: 90%;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 10px 0px 10px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px 10px;
  animation: fold-left-in 0.3s ease-in-out;
}
@keyframes fold-left-in {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
.one-card2 {
  width: 20%;
  height: 90%;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 10px 0px 10px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px 10px;
  animation: fold-left-in2 0.3s ease-in-out;
}
@keyframes fold-left-in2 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
.card-name {
  font-size: var(--14px-to-rem);
  color: #707070;
  padding: 0 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}
.card-price {
  font-size: var(--14px-to-rem);
  font-weight: 600;
  color: #2e2e2e;
  padding: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}
.card-image-div {
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #f5f5f5;
  width: 90%;
  height: 40%;
  display: flex;
  justify-content: center;
}
.card-imge {
  width: 70px;
  height: 70px;
  margin: 5px;
}
.previous-record-btn {
  position: relative;
  height: 24px;
  width: 24px;
  display: inherit;

  &::after {
    position: absolute;
    content: '';
    background: url(@/assets/img/arrow/ic_arrow_left.png);
    cursor: pointer;
    width: inherit;
    height: inherit;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.next-record-btn {
  position: relative;
  height: 24px;
  width: 24px;
  display: inherit;

  &::after {
    position: absolute;
    content: '';
    background: url(@/assets/img/arrow/ic_arrow_right.png);
    cursor: pointer;
    width: inherit;
    height: inherit;
  }
}
</style>
