<template>
    <sweet-dialog v-model="dialogVisible" :title="$t('ccm.agent.contact.createbother')" width="616px"
                  :close-on-click-modal="false">
        <sweet-form
                ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
                label-width="auto"
                class="demo-ruleForm"
                :label-position="labelPosition">
            <!-- 特殊名单类型 -->
            <sweet-form-item :label="$t('ccm.speciallist.msg.sltype')" prop="typeId">
              <sweet-config-provider :locale="pageLang">
                <el-select v-model="ruleForm.typeId" :placeholder="$t('aicc.select')"
                           :fit-input-width="true" class="aicc-form-input-width">
                  <el-option v-for="item in selectionTypes" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </sweet-config-provider>
            </sweet-form-item>
            <!-- 用户号码 -->
            <sweet-form-item :label="$t('ccm.speciallist.msg.userNo')" prop="userNo">
                <sweet-input v-model="ruleForm.userNo" class="aicc-form-input-width" :placeholder="$t('aicc.input')"/>
            </sweet-form-item>
            <!-- 接触信息 -->
            <sweet-divider content-position="left">{{ $t('ccm.agent.contact.contactinfo') }}</sweet-divider>
            <!-- 接触流水 -->
            <sweet-form-item :label="$t('ccm.agent.contact.callserialno')" prop="callSerialNo">
                <sweet-input v-model="ruleForm.callSerialNo" disabled class="aicc-form-input-width"/>
            </sweet-form-item>
            <!-- 接触编号 -->
            <sweet-form-item :label="$t('ccm.agent.contact.contactno')" prop="contactId">
                <sweet-input v-model="ruleForm.contactId" disabled class="aicc-form-input-width"/>
            </sweet-form-item>
            <!-- 加入原因 -->
            <sweet-form-item :label="$t('ccm.agent.contact.joinreason')" prop="joinReason">
                <sweet-input v-model="ruleForm.joinReason" :rows="4" type="textarea" class="aicc-form-input-width"
                             :placeholder="$t('aicc.input')"/>
            </sweet-form-item>
        </sweet-form>
        <template #footer>
            <span class="dialog-footer">
                <sweet-button @click="dialogVisible = false">{{ $t('ccm.agent.button.cancel') }}</sweet-button>
                <sweet-button type="primary" @click="submitForm('ruleForm')" :disabled="buttonControll">{{
                    $t('ccm.agent.button.save')
                  }}</sweet-button>
            </span>
        </template>
    </sweet-dialog>
</template>


<script>
    import {botherAdd, queryContactInfo, querySpecialListType} from '@/views/ccmanagement/ccma-api/index';
    import {ElMessageBox} from 'element-plus';
    import $ from 'jquery';
    import {getLanguage} from "@/lang";
    import zhCn from "element-plus/dist/locale/zh-cn.mjs";
    import enUs from 'element-plus/dist/locale/en.min.mjs';
    import fr from 'element-plus/dist/locale/fr.mjs';
    import es from 'element-plus/dist/locale/es.mjs';
    import ptBr from "element-plus/dist/locale/pt-br.mjs";
    import th from 'element-plus/dist/locale/th.mjs';
    import ar from 'element-plus/dist/locale/ar.mjs';
    import validate from "@/utils/validate";
    import AiccElMessage from "@/utils/el-message";

    const langMap = new Map([
      ["zh_CN", zhCn],
      ['en_US', enUs],
      ['fr_FR', fr],
      ['th_TH', th],
      ['pt_BR', ptBr],
      ['es_ES', es],
      ['ar_SA', ar]
    ]);

    window.$ = $;
    window.jQuery = $;

    export default {
        name: 'BotherCall',
        props: {
            show: {
                type: Boolean,
                default: false
            },
            workno: {
                type: String
            },
            callid: {
                type: String
            }
        },
        data() {
            var emailOrPhoneValidate = (rule, value, callback) => {
                let emailReg = /^[a-zA-Z0-9_-ÁáÉéxÍÍíÓóÚúÂâÊêÔôÀàÃãÕõÜüÇç@\"\s!#$%&'*+-/=?^_`{|}~]+$/; // 邮箱格式校验，大小写不敏感
                let phoneReg = /^[0-9]*$/;
                let email = false;
                let phoneNum = false;
                let validateFlag = false;
                if (emailReg.test(value)) {
                    email = true;
                }
                if (phoneReg.test(value)) {
                    phoneNum = true;
                }
                if (email || phoneNum) {
                    validateFlag = true;
                }
                if (!validateFlag) {
                    callback(new Error($t('ccm.agent.addbother.msg.emailOrPhoneValidate')));
                } else {
                    callback();
                }
            };
            var specialStrValidate = (rule, value, callback) => {
                var regEn = /^=|['"\/<>\\]|exec|eval/gi;
                if (regEn.test(value)) {
                    callback(new Error($t('ccm.custdata.msg.msgValidateFailed')));
                } else {
                    callback();
                }
            };
            return {
                pageLang: langMap.get(getLanguage()),
                selectionTypes: [],
                agentno: '',
                buttonControll: true,
                rules: {
                    typeId: [
                        {required: true, message: this.$t('aicc.select'), trigger: 'blur'}
                    ],
                    userNo: [
                        {required: true, message: this.$t('ccm.agent.label.mobileagententer'), trigger: 'blur'},
                        {maxLength: 100, validator: validate.maxLengthValidator, trigger: ['blur', 'change']},
                        {validator: emailOrPhoneValidate, trigger: 'blur'}
                    ],
                    joinReason: [
                        {required: true, message: this.$t('ccm.agent.label.mobileagententer'), trigger: 'blur'},
                        {maxLength: 1024, validator: validate.maxLengthValidator, trigger: ['blur', 'change']},
                        {validator: specialStrValidate, trigger: 'blur'}
                    ]
                },
                ruleForm: {
                    typeId: '',
                    userNo: '',
                    joinReason: '',
                    callSerialNo: '',
                    contactId: ''
                }
            }
        },
        computed: {
            dialogVisible: {
                get() {
                    return this.show
                },
                set(val) {
                    this.$emit('update:show', val)
                }
            },
            labelPosition() {
                return window.isRTL ? 'right' : 'left'
            }
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.add()
                    } else {
                        return false
                    }
                })
            },
            querySpListType() {
                querySpecialListType().then(data => {
                    this.selectionTypes = [];
                    for (let key of Object.keys(data)) {
                        let listTypeItem =
                            {
                                "label": data[key],
                                "value": key
                            };
                        this.selectionTypes.push(listTypeItem);
                    }
                    this.agentno = this.workno;
                    this.queryContactDetail();
                })
            },
            queryContactDetail() {
              let tempCallId = this.callid;
              if (!tempCallId) {
                if (!window.GLOBAL_CURRENT_DEALCALLID) {
                  return;
                }
                tempCallId = window.GLOBAL_CURRENT_DEALCALLID;
              }
                queryContactInfo({"callId": tempCallId, "workNo": this.agentno}).then(data => {
                    if (data && data.returnCode == '0') {
                        this.ruleForm.callSerialNo = data.callSerialno;
                        this.ruleForm.contactId = data.contactId;
                        this.ruleForm.userNo = data.acceptNo
                        this.buttonControll = false;
                    } else {
                        this.buttonControll = true;
                    }
                })
            },
            add() {
                botherAdd(this.ruleForm).then(data => {
                    if (data && data.returnCode == '0') {
                        AiccElMessage.success($t('ccm.agent.addbother.success'))
                        this.dialogVisible = false;
                    } else {
                        AiccElMessage.error($t('ccm.agent.addbother.fail'))
                    }
                })
            }
        },
        mounted() {
            this.querySpListType();
        }
    }
</script>

<style>
.auto-width {
  min-width: unset !important;
  max-width: unset !important;
}
</style>

<style lang="less" scoped>
.dialog-footer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}
</style>