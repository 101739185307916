<template>
  <div class="buttom-card">
    <div class="aicc-poc-scrolling-image-title">PRIMARY OFFERING</div>
    <div class="one-card">
      <img class="card-image" src="@/assets/img/phone-card.png" alt="" />
      <div class="right-area">
        <div class="card-name">Red YOU L(PPS)</div>
        <div class="card-price">
          <div class="red">$ 50.00</div>
          <div class="gray">/Month</div>
        </div>
        <div class="date">2023-06-28 03:57:27 ~ 2025-01-01 00:59:59</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'bottom-card',
  mounted() {}
}
</script>

<style lang="less" scoped>
.buttom-card {
  .aicc-poc-scrolling-image-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;
    font-weight: 700;
  }

  .one-card {
    padding: 24px 20px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;

    .card-image {
      height: 70px;
    }

    .right-area {
      flex: 1;
      margin-left: 24px;

      .card-name {
        font-size: var(--20px-to-rem);
        font-weight: 500;
        margin-bottom: 20px;
      }

      .card-price {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .red {
          color: #c80812;
          font-size: var(--20px-to-rem);
          font-weight: 600;
        }

        .gray {
          margin-left: 12px;
          color: #8e8e8e;
          font-size: var(--16px-to-rem);
          font-weight: 400;
        }
      }

      .date {
        color: #8e8e8e;
        font-size: var(--16px-to-rem);
        font-weight: 400;
      }
    }
  }
}
</style>
