import request from '@/utils/request'

export function queryCallInfoByCallId(callId, agentId) {
  const data = {
    callId: callId,
    agentId: agentId
  }
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextrecord/queryCallInfoByCallId',
    method: 'post',
    data
  })
}

export function queryAgentAIFlagById() {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ccagent/queryAgentAIFlagById',
    method: 'post'
  })
}

export function queryParamIsShowCallData() {
  return request({
    url: '/service-cloud/rest/cc-management/v1/sysParameter/queryParamIsShowCallData',
    method: 'post'
  })
}

export function queryAgentSkillsApi(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/skill/queryAgentSkills',
    method: 'post',
    data
  })
}

export function queryTenantSysParamById(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/sysparam/queryTenantSysParamById',
    method: 'post',
    data
  })
}

export function getauths(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/thirdparty/getauths',
    method: 'get',
    data
  })
}

export function queryEnabledVerifyIdentityConfig(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/verifyidentityconfig/queryEnabledVerifyIdentityConfig',
    method: 'get',
    data
  })
}

export function verifyIdentityflow(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/verifyidentity/verifyIdentityflow',
    method: 'post',
    data
  })
}

export function isCreateCase() {
  return request({
    url: '/service-cloud/rest/cc-management/v1/ccagent/isCreateCase',
    method: 'get',
    data: {type: 'AUDIOVIDEO'}
  })
}

export function textClassification(text) {
  const data = {
    text: text
  }
  return request({
    url: '/service-cloud/rest/cc-ai/v1/tenantidentification/textClassification',
    method: 'post',
    data
  })
}

export function queryEnabledPageUrls() {
  const data = {
    mediaChannel: "0"
  }
  return request({
    url: '/service-cloud/rest/cc-management/v1/pageurls/queryEnabledPageUrls',
    method: 'post',
    data
  })
}

export function queryAccountNameByWorkNos(callerCopy) {
  const data = {
    workNos: callerCopy
  }
  return request({
    url: '/service-cloud/rest/cc-management/v1/ccagent/queryAccountNameByWorkNos',
    method: 'post',
    data
  })
}

export function doRecallByCallId(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/agents/doRecallByCallId',
    method: 'post',
    data
  })
}

export function updateContactAcceptNo(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextrecord/updateContactAcceptNo',
    method: 'post',
    data
  })
}

export function queryUserContactLimit(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextrecord/queryUserContactLimit',
    method: 'post',
    data
  })
}

export function queryContactByAcceptNo(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/contactextrecord/queryContactByAcceptNo',
    method: 'post',
    data
  })
}

export function queryCallReasonInfo() {
  return request({
    url: '/service-cloud/rest/cc-management/v1/callreason/queryCallReasonInfo',
    method: 'post'
  })
}

export function queryContactCustInfoConfig(url, callId) {
  const data = {
    callId: callId
  }
  return request({
    url: url,
    method: 'post',
    data
  })
}

export function queryASRResult(callId) {
  const data = {
    callId: callId
  }
  return request({
    url: '/service-cloud/rest/cc-ai/v1/intelligentidentify/queryASRResult',
    method: 'post',
    data
  })
}

export function queryInteractiveRecord(data) {
  return request({
    url: '/oifde/rest/cc-ivr/v1/interactiverecord/queryInteractiveRecord',
    method: 'post',
    data
  })
}

export function queryCustomInfoApi(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/customTable/queryCustomInfo',
    method: 'post',
    data
  })
}

export function modifyCustomInfo(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/customTable/modifyCustomInfo',
    method: 'post',
    data
  })
}

export const queryTenantFeaturesForWeb = data => {
  return request({
    url: '/service-cloud/rest/cc-management/v1/tenantInfo/queryTenantFeaturesForWeb',
    method: 'post',
    data
  })
}


export function queryAiSummary(data) {
  return request({
    url: '/agent_assistant/summarize',
    method: 'post',
    data
  })
}

export function queryAiIntention(data) {
  return request({
    url: '/agent_assistant/intent',
    method: 'post',
    data
  })
}

export function queryAiAssistant(data) {
  return request({
    url: '/agent_assistant/reply',
    method: 'post',
    data
  })
}

export function queryCaseType(data) {
  return request({
    url: '/service/AICC__Case/1.0.0/caseType/list',
    method: 'post',
    data
  })
}


export function queryCustomerList(data) {
  return request({
    url: '/service/AICC__Case/1.0.0/case/contact/list',
    method: 'post',
    data
  })
}

export function queryCaseTemplate(templateId) {
  return request({
    url: '/service/AICC__Case/1.0.0/caseTemplate/' + templateId,
    method: 'get'
  })
}

export function queryCaseTemplateFields(data) {
  return request({
    url: '/u-route/baas/metadata/v1.0/object/AICC__Case__CST',
    method: 'get',
    data
  })
}

export function queryCase(data) {
  return request({
    url: '/agent_assistant/case',
    method: 'post',
    data
  })
}

export function queryCustomerInfo(data) {
  return request({
    url: '/service-cloud/rest/cc-management/v1/demodata/customerInfo',
    method: 'post',
    data
  })
}