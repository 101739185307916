<template>
  <div class="right-content-lower">
    <div class="lower-title" v-text="$t('agentconsole.contacthistory.title')"></div>
    <div class="lower-reason" v-if="resultModel.length>0">
      <div class="reason-li" v-for="($item,index) in resultModel" :key="index" v-if="!isMirrorLanguage">
        <sweet-tooltip :content="$item.fullReason || '--'" placement="right" popper-class="aicc-ccma-reason-info-max-size">
          <div class="reason-label" @click="showContactDetail($item.callSerialno,$item.countId,$item.beginTime);">
            <img class="reason-label-img" v-if="4>$item.mediaAbility && $item.calltype=='0'" alt=""
                 src="@/views/agentconsole/assets/img/icon/IPCC/IMG/call@2x.png"/>
            <img class="reason-label-img" v-if="4>$item.mediaAbility && $item.calltype=='11'" alt=""
                 src="@/views/agentconsole/assets/img/icon/IPCC/IMG/call@2x.png"/>
            <img class="reason-label-img" v-if="4>$item.mediaAbility && $item.calltype=='12'" alt=""
                 src="@/views/agentconsole/assets/img/icon/IPCC/IMG/call@2x.png"/>
            <img class="reason-label-img" v-if="4>$item.mediaAbility && $item.calltype=='1'" alt=""
                 src="@/views/agentconsole/assets/img/icon/IPCC/IMG/call@2x.png"/>
            <img class="reason-label-img" v-if="4>$item.mediaAbility && $item.calltype=='2'" alt=""
                 src="@/views/agentconsole/assets/img/icon/IPCC/IMG/call@2x.png"/>
            <img class="reason-label-img" v-if="4>$item.mediaAbility && $item.calltype=='3'" alt=""
                 src="@/views/agentconsole/assets/img/icon/IPCC/IMG/call@2x.png"/>
            <img class="reason-label-img" v-if="4>$item.mediaAbility && $item.calltype=='11'" alt=""
                 src="@/views/agentconsole/assets/img/icon/IPCC/IMG/call@2x.png"/>
            <img class="reason-label-img" v-if="$item.mediaAbility=='5' && $item.channelType=='1'"
                 alt="" src="@/views/agentconsole/assets/img/icon/IPCC/IMG/web@2x.png"/>
            <img class="reason-label-img" v-if="$item.mediaAbility=='5' && $item.channelType=='2'"
                 alt="" src="@/views/agentconsole/assets/img/icon/IPCC/IMG/wecat@2x.png"/>
            <img class="reason-label-img" v-if="$item.mediaAbility=='5' && $item.channelType=='3'"
                 alt="" src="@/views/agentconsole/assets/img/icon/IPCC/IMG/facebook@2x.png"/>
            <img class="reason-label-img" v-if="$item.mediaAbility=='5' && $item.channelType=='4'"
                 alt="" src="@/views/agentconsole/assets/img/icon/IPCC/IMG/Twitter@2x.png"/>
            <img class="reason-label-img" v-if="$item.mediaAbility=='5' && $item.channelType=='5'"
                 alt="" src="@/views/agentconsole/assets/img/icon/IPCC/IMG/5g@2x.png"/>
            <img class="reason-label-img" v-if="$item.mediaAbility=='5' && $item.channelType=='6'"
                 alt="" src="@/views/agentconsole/assets/img/icon/IPCC/IMG/email@2x.png"/>
            <img class="reason-label-img" v-if="$item.mediaAbility=='5' && $item.channelType=='7'"
                 alt="" src="@/views/agentconsole/assets/img/icon/IPCC/IMG/video@2x.png"/>
            <img class="reason-label-img" v-if="$item.mediaAbility=='5' && $item.channelType=='8'"
                 alt="" src="@/views/agentconsole/assets/img/icon/IPCC/IMG/line@2x.png"/>
            <img class="reason-label-img" v-if="$item.mediaAbility=='5' && $item.channelType=='9'"
                 alt="" src="@/views/agentconsole/assets/img/icon/IPCC/IMG/whatsapp@2x.png"/>
            <img class="reason-label-img" v-if="$item.mediaAbility=='5' && $item.channelType=='10'"
                 alt="" src="@/views/agentconsole/assets/img/icon/IPCC/IMG/message@2x.png"/>
              <div class="reason-label-title tooLong100px">{{ $item.reason || '--' }}</div>
          </div>
        </sweet-tooltip>
        <div class="reason-time">{{ $item.showTime }}</div>
      </div>
      <div class="reason-li" v-for="($item,rtlIndex) in resultModel" :key="rtlIndex" v-else>
        <div class="reason-time">{{ $item.showTime }}</div>
        <sweet-tooltip :content="$item.fullReason || '--'" placement="right" popper-class="aicc-ccma-reason-info-max-size">
          <div class="reason-label" @click="showContactDetail($item.callSerialno,$item.countId,$item.beginTime);">
            <img class="reason-label-img" v-if="4>$item.mediaAbility && $item.calltype=='0'" alt=""
                 src="@/views/agentconsole/assets/img/icon/IPCC/IMG/call@2x.png"/>
            <img class="reason-label-img" v-if="4>$item.mediaAbility && $item.calltype=='11'" alt=""
                 src="@/views/agentconsole/assets/img/icon/IPCC/IMG/call@2x.png"/>
            <img class="reason-label-img" v-if="4>$item.mediaAbility && $item.calltype=='12'" alt=""
                 src="@/views/agentconsole/assets/img/icon/IPCC/IMG/call@2x.png"/>
            <img class="reason-label-img" v-if="4>$item.mediaAbility && $item.calltype=='1'" alt=""
                 src="@/views/agentconsole/assets/img/icon/IPCC/IMG/call@2x.png"/>
            <img class="reason-label-img" v-if="4>$item.mediaAbility && $item.calltype=='2'" alt=""
                 src="@/views/agentconsole/assets/img/icon/IPCC/IMG/call@2x.png"/>
            <img class="reason-label-img" v-if="4>$item.mediaAbility && $item.calltype=='3'" alt=""
                 src="@/views/agentconsole/assets/img/icon/IPCC/IMG/call@2x.png"/>
            <img class="reason-label-img" v-if="4>$item.mediaAbility && $item.calltype=='11'" alt=""
                 src="@/views/agentconsole/assets/img/icon/IPCC/IMG/call@2x.png"/>
            <img class="reason-label-img" v-if="$item.mediaAbility=='5' && $item.channelType=='1'"
                 alt="" src="@/views/agentconsole/assets/img/icon/IPCC/IMG/web@2x.png"/>
            <img class="reason-label-img" v-if="$item.mediaAbility=='5' && $item.channelType=='2'"
                 alt="" src="@/views/agentconsole/assets/img/icon/IPCC/IMG/wecat@2x.png"/>
            <img class="reason-label-img" v-if="$item.mediaAbility=='5' && $item.channelType=='3'"
                 alt="" src="@/views/agentconsole/assets/img/icon/IPCC/IMG/facebook@2x.png"/>
            <img class="reason-label-img" v-if="$item.mediaAbility=='5' && $item.channelType=='4'"
                 alt="" src="@/views/agentconsole/assets/img/icon/IPCC/IMG/Twitter@2x.png"/>
            <img class="reason-label-img" v-if="$item.mediaAbility=='5' && $item.channelType=='5'"
                 alt="" src="@/views/agentconsole/assets/img/icon/IPCC/IMG/5g@2x.png"/>
            <img class="reason-label-img" v-if="$item.mediaAbility=='5' && $item.channelType=='6'"
                 alt="" src="@/views/agentconsole/assets/img/icon/IPCC/IMG/email@2x.png"/>
            <img class="reason-label-img" v-if="$item.mediaAbility=='5' && $item.channelType=='7'"
                 alt="" src="@/views/agentconsole/assets/img/icon/IPCC/IMG/video@2x.png"/>
            <img class="reason-label-img" v-if="$item.mediaAbility=='5' && $item.channelType=='8'"
                 alt="" src="@/views/agentconsole/assets/img/icon/IPCC/IMG/line@2x.png"/>
            <img class="reason-label-img" v-if="$item.mediaAbility=='5' && $item.channelType=='9'"
                 alt="" src="@/views/agentconsole/assets/img/icon/IPCC/IMG/whatsapp@2x.png"/>
            <img class="reason-label-img" v-if="$item.mediaAbility=='5' && $item.channelType=='10'"
                 alt="" src="@/views/agentconsole/assets/img/icon/IPCC/IMG/message@2x.png"/>
            <div class="reason-label-title tooLong100px">{{ $item.reason || '--' }}</div>
          </div>
        </sweet-tooltip>
      </div>
    </div>
    <div class="lower-button" v-if="resultModel.length>0">
      <span @click="showContact();">{{ $t('SM.LOGIN.TITLE.NOTICE_MORE') }}</span>
    </div>
    <div class="hlds-p-vertical--small hlds-is-relative" style="margin-top:40px;text-align: center;"
         v-if="!resultModel.length>0">
      <div class="no-contactinfo">
        {{ $t('agentconsole.contacthistory.nocontactinfo') }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  getauths,
  queryCallReasonInfo,
  queryContactByAcceptNo,
  queryUserContactLimit
} from "@/views/agentconsole/api/audio-video-workbench";
import {useCallIdStore} from "@/views/agentconsole/stores";
import $ from 'jquery';
import {ElMessageBox} from "element-plus";
import {computed} from "vue";
import AiccElMessage from "@/utils/el-message";

window.$ = $;
window.jQuery = $;

export default {
  name: 'ContactHistory',
  setup() {
    const agentConsoleTabStore = window.useAgentConsoleTabStore();
    const callIdStore = useCallIdStore();
    return {agentConsoleTabStore, callIdStore}
  },
  props: {
    isaudioandvideo: {
      type: Boolean,
      default: false
    },
    hasCallBackAuth: {
      type: Boolean,
      default: false
    },
    eventStoreData: {
      default: null
    },
    // 接触记录callId
    contactCallId: {
      type: [String, Number],
      required: true
    },
  },
  data() {
    return {
      showModel: {},
      params: {},
      nowTime: 0,
      acceptNo: "",
      searchObject: {},
      showResult: [],
      isRest: false,
      resultModel: [],
      callid: "",
      cacheMap: {},
      MILLISECONDS_OF_HOUR: 36e5,
      MILLISECONDS_OF_DAY: 36e5 * 24,
      MILLISECONDS_OF_MIN: 60000,
      callback_authId: "80031001001016", // 回呼按钮权限
      has_callBack_auth: false,
      $Model: window.$Model
    }
  },
  watch: {
    eventStoreData: {
      handler(newMsg, oldMsg) {
        this.checkContactState(this.eventStoreData);
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    computedCallId() {
      return this.contactCallId || this.callid;
    },
    isMirrorLanguage() {
      return window.isRTL;
    },
  },
  methods: {
    initData() {
      if (this.params['callid'] || this.params['mediaAbility'] == 5) {
        if (this.params['mediaAbility'] == 5) {
          sessionStorage.setItem("multimediaCallId", this.params['callid']);
        } else {
          this.params['callid'] = sessionStorage.getItem("callid") || this.params['callid']
          sessionStorage.setItem("callid", this.params['callid']);
          sessionStorage.setItem("asr_callid", sessionStorage.getItem("callid"));
        }
        sessionStorage.setItem("mediaAbility", this.params['mediaAbility']);
        this.showModel['callid'] = this.params['callid'];
        this.showModel['mediaAbility'] = this.params['mediaAbility'];
      } else {
        if (window.location.href.indexOf('audioandvideoworkbench.html') != -1) {
          this.showModel['callid'] = sessionStorage.getItem("callid") || getQueryString('callid') || "--";
          if (!sessionStorage.getItem("callid") && getQueryString('callid')) {
            sessionStorage.setItem("callid", this.showModel['callid']);
            sessionStorage.setItem("asr_callid", sessionStorage.getItem("callid"));
          }
        } else {
          this.showModel['callid'] = sessionStorage.getItem("multimediaCallId") || "--";
        }
        this.showModel['mediaAbility'] = sessionStorage.getItem("mediaAbility") || "--";
      }
      this.callid = this.showModel['callid'];
      var callBackParam = {};
      callBackParam['$callback'] = (respBody) => {
        if (respBody?.result?.agentState == 4) {
          this.isRest = true;
        }
      }

      window.top['CCAgent']?.getAgentStatus(callBackParam);
      this.getContactByAcceptNo(this.callid, null);
      this.judgeRecallAuth();
    },

    getContactByAcceptNo(callid, mediaAbility) {
      // DTS202105140IVYE1P0H00 前台查询历史接触时，不再从缓存中获取，调后台接口查询
      // 多媒体类型的消息，因为要根据受理号码查询，需要和音视频的做区分
      if ('--' == this.callid) {
        return;
      }
      if (this.callid && this.callid != "" && window.$Model) {
        window.$Model.currentRecordCallId = callid;
        this.callIdStore.setCallId(callid);
      }

      this.queryContact(callid, mediaAbility);
    },

    judgeRecallAuth() {
      // 如果页面通过传参给 has_callBack_auth 赋值为 true 不再调用接口去后台查询
      if (this.has_callBack_auth) {
        return;
      }
      getauths({}).then(data => {
        if (data) {
          if (jQuery.inArray(this.callback_authId, data["permCodes"]) != -1) {
            this.has_callBack_auth = true;
          }
        }
      })
    },

    queryContact(callid, mediaAbility) {
      // caller为空的情况下callid不能为空
      if (callid === null || callid === "" || callid === "--") {
        return;
      }

      this.showResult = [];
      let serviceUrl = "";
      serviceUrl = "/cc-management/v1/contactextrecord/queryUserContactLimit";
      this.searchObject['callid'] = callid;
      if (mediaAbility) {
        this.searchObject['mediaAbility'] = mediaAbility;
      }
      queryUserContactLimit(this.searchObject).then(resultModel => {
        this.resultModel = resultModel;
        if (null == this.resultModel && callid == null && mediaAbility == 5) {
          return;
        }
        if (null == this.resultModel) {
          AiccElMessage.info($t('ccm.agent.operation.vdnNotEnabled'));
          return ;
        }
        if (this.resultModel.length > 0) {
          if (this.resultModel[0].callId == this.showModel['callid']) {
            this.resultModel.splice(0, 1);
            this.isRest = false;
          }
          // 取前3条接触记录
          if (this.resultModel.length > 3) {
            this.resultModel.splice(3, this.resultModel.length - 3);
          }
          this.nowTime = new Date($.ajax({async: false}).getResponseHeader("Date")).getTime();
          for (var index = 0; index < this.resultModel.length; index++) {
            this.resultModel[index].showTime = this.getTimeRange(this.resultModel[index].beginTime);
          }
          this.cacheMap[callid] = this.resultModel;

          for (let i = 0; i < this.resultModel.length; i++) {
            if (this.resultModel[i].talkReason) {
              this.queryCallReason();
              break;
            }
          }
        }
      })
    },

    getContactByAccept(acceptNo) {
      if (!acceptNo) {
        return;
      }
      this.queryContactByAccept(acceptNo);
    },

    queryContactByAccept(acceptNo) {
      this.searchObject['acceptNo'] = acceptNo;
      queryContactByAcceptNo(this.searchObject).then(resultModel => {
        this.resultModel = resultModel;
        if (null == this.resultModel) {
          AiccElMessage.info($t('ccm.agent.operation.vdnNotEnabled'));
        } else {
          if (this.resultModel.length > 0) {
            this.callid = this.resultModel[0].callId;
            if (this.resultModel.length > 3) {
              this.resultModel.splice(3, this.resultModel.length - 3);
            }
            this.nowTime = new Date($.ajax({async: false}).getResponseHeader("Date")).getTime();
            for (var index = 0; index < this.resultModel.length; index++) {
              this.resultModel[index].showTime = this.getTimeRange(this.resultModel[index].beginTime);
            }
            for (let i = 0; i < this.resultModel.length; i++) {
              if (this.resultModel[i].talkReason) {
                this.queryCallReason();
                break;
              }
            }
          }
        }
      })
    },

    escapeHtml(text) {
      text = '' + text;
      var map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "''": '&#039;',
        '/': '/',
        '(': '&#40;',
        ')': '&#41;'

      };

      return text.replace(/[&<>""'()/]/g, function (m) {
        return map[m];
      });
    },

    queryCallReason() {
      queryCallReasonInfo().then(data => {
        if (data) {
          (this.resultModel || []).forEach(element => {
            if (element.talkReason) {
              const list = element.talkReason.split(",");
              let reason = "";
              for (var i = 0, len = list.length; i < len; i++) {
                if (i > 0) {
                  reason += "," + (data.find((item) => {
                    return item.id == list[i]
                  })).name;
                } else {
                  reason += (data.find((item) => {
                    return item.id == list[i]
                  })).name;
                }
              }
              element.reason = this.escapeHtml(reason);
              const fullReason = []
              list.forEach(item => {
                const nameArr = []
                this.handleFullPathReason(item, data, nameArr)
                fullReason.push(nameArr.reverse().join(' / '))
              })
              element.fullReason = this.escapeHtml(fullReason.join(', '))
            }
          });
        }
      })
    },

    // 从平铺数据中，找出完整层级的来电原因
    handleFullPathReason(id, treeData, nameArr) {
      const leaf = treeData.find(item => item.id === id)
      const name = leaf.name
      const parentId = leaf.parentId
      nameArr.push(name)
      if (parentId === '0') {
        return
      }
      this.handleFullPathReason(parentId, treeData, nameArr)
    },

    getTimeRange(beginTime) {
      var min;
      min = this.nowTime - beginTime;
      if (min < 0) {
        return $t('agentconsole.contacthistory.oneMin');
      }
      if (min < this.MILLISECONDS_OF_HOUR) {//一小时内
        if (Math.floor(min / this.MILLISECONDS_OF_MIN) == 0) {
          return $t('agentconsole.contacthistory.oneMin');
        } else {
          return Math.floor(min / this.MILLISECONDS_OF_MIN) + $t('agentconsole.contacthistory.oneMinAgo');
        }
      } else if (min < this.MILLISECONDS_OF_DAY) {//一天内
        return Math.floor(min / this.MILLISECONDS_OF_HOUR) + $t('agentconsole.contacthistory.hourAgo');
      } else {//计算天数
        return Math.floor(min / (this.MILLISECONDS_OF_DAY)) + $t('agentconsole.contacthistory.dayAgo');
      }

    },

    showContactDetail(callSerialno, countId, beginTime) {
      // 此处和appcube有联动影响，如果需要修改页面跳转路由，则需要提前和appcube沟通下，确保两边的逻辑一致，不影响现有功能
      if (callSerialno && this.callid) {
        window.showTab({
          title: $t('ccm.agent.contact.contactdetail'),
          name: "ccmaContactDetailNewTab",
          id: `ccmaContactDetailNewTab`,
          params: {id: callSerialno},
          query: {
            callSerialno: callSerialno,
            callid: this.callid,
            beginTime: beginTime,
            originMenuId: this.$route.name
          },
        });
      }
    },
    showContact() {
      // 此处和appcube有联动影响，如果需要修改页面跳转路由，则需要提前和appcube沟通下，确保两边的逻辑一致，不影响现有功能
      window.showTab({
        id:'ccmacontractqueryagenthis',
        title: $t('agentconsole.contacthistory.title'),
        name: "ccmacontractqueryagenthis",
        query: {callid: this.computedCallId, originMenuId: this.$route.name}
      })
    },
    checkContactState(e) {
      this.params = e.pageParams;
      if (!this.params) {
        return;
      }
      //初始化或者更新逻辑
      if (this.params['name'] == "initPage") {
        this.showModel['callid'] = this.params['callid'] || (e.pageParams.callMess && e.pageParams.callMess.callid) || "--";
        this.showModel['mediaAbility'] = this.params['mediaAbility'] || (e.pageParams.callMess && e.pageParams.callMess.mediaAbility) || "--";
        this.callid = this.showModel['callid'];
        this.acceptNo = "";
        let mediaAbility = this.showModel['mediaAbility'] == 5 ? 5 : null;
        //判断是音视频还是多媒体分别存不同的callid
        if (mediaAbility == 5) {
          sessionStorage.setItem("multimediaCallId", this.params['callid'] || (e.pageParams.callMess && e.pageParams.callMess.callid));
        } else {
          sessionStorage.setItem("callid", this.params['callid'] || (e.pageParams.callMess && e.pageParams.callMess.callid));
          sessionStorage.setItem("asr_callid", sessionStorage.getItem("callid"));
        }
        sessionStorage.setItem("mediaAbility", this.params['mediaAbility'] || (e.pageParams.callMess && e.pageParams.callMess.mediaAbility));
        this.getContactByAcceptNo(this.callid, mediaAbility);
      }
      if (this.params['name'] == "hangUp") {
        delete this.cacheMap[e.pageParams.callMess && e.pageParams.callMess.caller];
        this.getContactByAcceptNo('--', null);
      }
      if (this.params['name'] == "mediaSocialHangUp") {
        delete this.cacheMap[e.pageParams.callMess && e.pageParams.callMess.caller];
        // 多媒体通话挂机后清空历史接触列表
        this.resultModel = []
        this.getContactByAcceptNo(null, 5);
      }
      if (this.params['name'] == "contactAcceptNum" && this.params['acceptNo']) {
        this.acceptNo = "";
        this.getContactByAccept(this.params['acceptNo']);
      }
    }
  },
  mounted() {
    this.has_callBack_auth = this.hasCallBackAuth;
    this.initData()
  }
}
</script>

<style lang="less" scoped>
@import '@/views/agentconsole/css/audioandvideoworkbench.css';
.right-content-lower {
  height: 300px;
  box-sizing: border-box;
  padding: 18px 0 !important;
}

.lower-reason {
  max-height: calc(100% - 72px);
  overflow-y: auto;
}

.lower-title {
  margin-bottom: 20px;
  font-size: var(--14px-to-rem);
  font-weight: 600;
}

.lower-button {
  height: 32px;
  width: 100%;
  font-size: 14px;
  color: #366bfc;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.reason-li {
  display: flex;
  margin: 16px 0;
}

body.RTL .reason-label .reason-label-title {
  transform: scaleX(-1) !important;
}

.reason-label {
  padding: 0 16px 0 6px;
  height: 31px;
  background: #f5f5f5;
  display: flex;
  line-height: 32px;
  border-radius: 2px 16px 16px 2px;
  align-items: center;
  min-width: 80px;
  cursor: pointer;
}

.reason-label-img {
  height: 32px !important;
  width: 32px;
  margin-right: 3px;
  margin-top: 4px;
  border-radius: 9px;
}

.reason-time {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #949494;
  text-align: right;
  line-height: 24px;
  font-weight: 400;
}

body.RTL .reason-time {
  flex: 2;
  justify-content: flex-start;
  text-align: left;
}

.page-right-tab {
  width: 61px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.right-tab-li {
  width: 100%;
  height: 61px;
}

.right-tab-li > img {
  height: 61px;
  width: 61px;
  cursor: pointer;
}
.reason-label-title{
  font-family: PingFangSC-Medium;
  font-size: 12px;
  color: #1C1C1C;
  text-align: right;
  line-height: 24px;
  font-weight: 500;
}
.no-contactinfo {
  margin-top: 10px;
  font-size: 12px;
  color: #cccccc;
  text-align: center;
  font-weight: normal;
}
</style>
<style lang="less">
.aicc-ccma-reason-info-max-size {
  max-width: 260px !important;
  max-height: 432px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  word-break: break-all;
}
</style>